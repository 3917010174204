import { Select, SelectProps } from 'antd';
import { formFilterOptionByLabel, IFormSelectOption } from 'features/Form';
import { IServiceBase, ServiceType } from 'features/Service';
import { useMemo } from 'react';
import { useStationServiceListLoadQuery } from '../../../StationService/StationService.api';
import { useStationListWithRoles } from '../../Station.hooks';

export function StationSelector({ service, serviceType, ...props }: {
  service: IServiceBase;
  serviceType: ServiceType;
} & SelectProps) {
  const { data = [], isLoading: isStationListLoading } = useStationListWithRoles();

  const { data: stationServiceList = [], isLoading: isStationServiceListLoading } = useStationServiceListLoadQuery({
    service_id: service.id,
    service_type: serviceType,
  });

  const options = useMemo(() => {
    if (isStationListLoading || isStationServiceListLoading) {
      return [];
    }
    const idList = stationServiceList.map((item) => item.station_id);

    return data
      .filter((item) => !idList.includes(item.id))
      .map((item): IFormSelectOption => ({
        title: item.info.name,
        value: item.id,
      }));
  }, [data, isStationListLoading, isStationServiceListLoading, stationServiceList]);

  return (
    <Select
      options={options}
      filterOption={formFilterOptionByLabel}
      showSearch
      loading={isStationListLoading || isStationServiceListLoading}
      {...props}
    />
  );
}
