import { Button, Flex, Form, FormInstance, FormListFieldData, FormListOperation, Input, Select } from 'antd';
import classnames from 'classnames';
import { FormListItem, FormListItemModeTypes, formRuleRequired, IFormSelectOption } from 'features/Form';
import { Spinner } from 'features/UI';
import { NamePath } from 'rc-field-form/lib/interface';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { arraySortBy } from 'utils/array';
import { usePageListLoadQuery } from '../../Page.api';
import { IMenuItemGroup, IMenuItemLink, IPage, IPageBlockEditFormProps, MenuItemTypes, PagePageTemplateTypes, PageViewTypes } from '../../Page.interface';
import { PAGE_BLOCK_CONTENT_FIELD, pageSlugToMenuItem } from '../../Page.model';

// ----- item

interface PageBlockMenuItemProps {
  field: FormListFieldData;
  form?: FormInstance;
  pageList: IPage[];
  fieldPath: NamePath;
  mode: FormListItemModeTypes;
}

function PageBlockMenuItemLink({ field, form, pageList, fieldPath, mode }: PageBlockMenuItemProps) {
  const [isInited, setIsInited] = useState<boolean>(false);
  const isCompact = useMemo(() => mode === FormListItemModeTypes.Compact, [mode]);
  const options = useMemo(() => pageList
    .map((item): IFormSelectOption => ({
      label: item.template === PagePageTemplateTypes.Internal
        ? `🔒 ${item.info.title}`
        : item.info.title,
      value: item.slug,
    }))
    .sort(arraySortBy('label')), [pageList]);

  const handleChange = useCallback((slug: string) => {
    form.setFieldValue([...fieldPath, field.name], pageSlugToMenuItem(slug, pageList));
  }, [field, fieldPath, form, pageList]);

  useEffect(() => {
    if (isInited) {
      return;
    }
    setIsInited(true);
    // reset value to last page's value
    const value: IMenuItemLink = form.getFieldValue([...fieldPath, field.name]);
    if (!value) {
      return;
    }
    handleChange(value.slug)
  }, [field, fieldPath, form, handleChange, isInited]);

  return (
    <div>
      <Form.Item
        label={!isCompact && 'Страница'}
        name={[field.name, 'slug']}
        rules={[formRuleRequired('Выберите страницу')]}
      >
        <Select
          options={options}
          onChange={handleChange}
          notFoundContent="Страница не найдена"
        />
      </Form.Item>
    </div>
  );
}

function PageBlockMenuItemGroup({ field, form, pageList, fieldPath }: PageBlockMenuItemProps) {
  return (
    <div>
      <Form.Item
        label="Название группы"
        name={[field.name, 'label']}
        rules={[formRuleRequired('Введите название')]}
      >
        <Input />
      </Form.Item>

      <div className="mb-2">Ссылки:</div>
      <Form.List name={[field.name, 'children']}>
        {createPageBlockMenuList(form, pageList, [...fieldPath, field.name, 'children'], FormListItemModeTypes.Compact)}
      </Form.List>
    </div>
  );
}

const pageBlockMenuListItemMap: Record<MenuItemTypes, FC<PageBlockMenuItemProps>> = {
  [MenuItemTypes.Group]: PageBlockMenuItemGroup,
  [MenuItemTypes.Link]: PageBlockMenuItemLink,
};


export function PageBlockMenuItem({ field, form, pageList, fieldPath, mode }: PageBlockMenuItemProps) {
  const Item = useMemo((): FC<PageBlockMenuItemProps> => {
    const value = form.getFieldValue([...fieldPath, field.name]);
    return pageBlockMenuListItemMap[value?.type];
  }, [field, fieldPath, form]);

  return (
    <Item
      field={field}
      form={form}
      pageList={pageList}
      fieldPath={fieldPath}
      mode={mode}
    />
  );
}

// ----- list

function PageBlockMenuMenuList({ fields, onAdd, onRemove, pageList, form, fieldPath, mode }: {
  fields: FormListFieldData[];
  onAdd: FormListOperation['add'];
  onRemove: FormListOperation['remove'];
  pageList: IPage[];
  form: FormInstance;
  fieldPath: NamePath;
  mode: FormListItemModeTypes;
}) {
  const isCompact = useMemo(() => mode === FormListItemModeTypes.Compact, [mode]);

  const handleAdd = useCallback((value: MenuItemTypes) => () => {
    switch (value) {
      case MenuItemTypes.Group:
        return onAdd({
          type: MenuItemTypes.Group,
          label: '',
          children: [],
        } as IMenuItemGroup);
      case MenuItemTypes.Link:
        return onAdd({
          type: MenuItemTypes.Link,
          url: '',
          slug: '',
          label: '',
        } as IMenuItemLink);
    }
  }, [onAdd]);

  const handleRemove = useCallback((name: FormListFieldData['name']) => () => {
    onRemove(name);
  }, [onRemove]);

  return (
    <Flex gap={4} vertical>
      {/* rows */}
      {fields.map((item) => (
        <FormListItem
          key={item.key}
          onRemove={handleRemove(item.name)}
          mode={mode}
        >
          <PageBlockMenuItem
            field={item}
            pageList={pageList}
            form={form}
            fieldPath={fieldPath}
            mode={mode}
          />
        </FormListItem>
      ))}
      <Flex gap={8} className={classnames('mb-2', {
        'mt-4': !isCompact,
      })}>
        {!isCompact && <Button onClick={handleAdd(MenuItemTypes.Group)}>Добавить группу</Button>}
        <Button onClick={handleAdd(MenuItemTypes.Link)}>Добавить ссылку</Button>
      </Flex>
    </Flex>
  );
}

export function createPageBlockMenuList(form: FormInstance, pageList: IPage[], fieldPath: NamePath, mode: FormListItemModeTypes) {
  return function (fields: FormListFieldData[], actions: FormListOperation) {
    return (
      <PageBlockMenuMenuList
        fields={fields}
        onAdd={actions.add}
        onRemove={actions.remove}
        pageList={pageList}
        form={form}
        fieldPath={fieldPath}
        mode={mode}
      />
    );
  };
}

export function PageBlockFormMenu({ form }: IPageBlockEditFormProps) {
  const { data, isLoading } = usePageListLoadQuery(PageViewTypes.Page);
  const menuList = useMemo(() => createPageBlockMenuList(form, data, [PAGE_BLOCK_CONTENT_FIELD], FormListItemModeTypes.Normal), [data, form]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Form.List name={PAGE_BLOCK_CONTENT_FIELD}>
      {menuList}
    </Form.List>
  );
}
