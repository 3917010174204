import { TabsProps } from 'antd';
import { IModerateFormModerateProps, ModerateForm } from 'features/Moderate';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { IServiceComplex, ServiceFormTabTypes, ServiceType } from '../../Service.interface';
import { serviceComplexFormItems } from '../../Service.model';
import { ServiceComplexBasicForm } from '../ServiceComplexBasicForm/ServiceComplexBasicForm';
import { ServiceStationTable } from '../ServiceStationTable/ServiceStationTable';

export function ServiceComplexForm({ serviceType, model, ...props }: {
  serviceType: ServiceType;
} & Omit<IModerateFormModerateProps<IServiceComplex>, 'items' | 'component'>) {
  const tabs = useMemo((): TabsProps['items'] => [
    {
      key: ServiceFormTabTypes.BasicForm,
      label: 'Основные',
      children: (
        <ModerateForm
          model={model}
          items={serviceComplexFormItems}
          component={ServiceComplexBasicForm}
          {...props}
        />
      ),
    },
    {
      key: ServiceFormTabTypes.StationTable,
      label: 'Вокзалы',
      disabled: !model,
      children: (
        <ServiceStationTable
          isList={model?.is_station_list}
          serviceType={serviceType}
          serviceSlug={model?.slug}
          serviceId={model?.id}
        />
      ),
    },
  ], [model, props, serviceType]);

  return (
    <UITabs
      defaultActiveKey={ServiceFormTabTypes.BasicForm}
      items={tabs}
    />
  );
}
