import { ExportOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { CatalogCategorySelector } from 'features/Catalog';
import { createFormCheckboxField, createFormLinkField, createFormTextField, FormItemTypes, IFormItemConfig } from 'features/Form';
import { createMediaAttachFormField, createMediaIconFormField } from 'features/Media';
import { ModerateStatusTypes } from 'features/Moderate';
import { createRichTextFormField } from 'features/RictText2';
import { ROLE_ADMIN_SUFFIX } from 'features/Role';
import { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { createURL } from 'utils/url';
import { useLazySimpleLoadQuery, useSimpleApproveMutation, useSimpleCreateMutation, useSimpleDeclineMutation, useSimpleInfoUpdateMutation, useSimpleListLoadQuery, useSimpleLoadQuery, useSimpleRemoveDraftMutation, useSimpleRemoveMutation, useSimpleStartModerationMutation, useSimpleUpdateMutation } from '../Simple/Simple.api';
import { IStationServiceTableItem } from '../StationService/StationService.intefrace';
import { IServiceBase, IStationLoader, ServiceFormTabTypes, ServiceType } from './Service.interface';
import { useFlagshipApproveMutation, useFlagshipCreateMutation, useFlagshipDeclineMutation, useFlagshipInfoUpdateMutation, useFlagshipListLoadQuery, useFlagshipLoadQuery, useFlagshipRemoveDraftMutation, useFlagshipRemoveMutation, useFlagshipStartModerationMutation, useFlagshipUpdateMutation, useLazyFlagshipLoadQuery } from './ServiceFlagship.api';
import { useLazyPremiumLoadQuery, usePremiumApproveMutation, usePremiumCreateMutation, usePremiumDeclineMutation, usePremiumInfoUpdateMutation, usePremiumListLoadQuery, usePremiumLoadQuery, usePremiumRemoveDraftMutation, usePremiumRemoveMutation, usePremiumStartModerationMutation, usePremiumUpdateMutation } from './ServicePremium.api';

const SIMPLE_FILE_PREFIX = 'simple';
const SERVICE_FILE_PREFIX = 'service';

export const serviceFormBackUrl = (serviceType: ServiceType) => createURL(`/services`, {
  tab: serviceType,
});
export const serviceEditUrl = (serviceType: ServiceType, serviceSlug: string, status: ModerateStatusTypes) => createURL(`/services/${serviceType}/${serviceSlug}/${status}`);
export const serviceStationCreateUrl = (serviceType: ServiceType, serviceSlug: string) => createURL(`/services/${serviceType}/${serviceSlug}/station/create`);
export const serviceStationFormBackUrl = (serviceType: ServiceType, serviceSlug: string) => createURL(`/services/${serviceType}/${serviceSlug}`, {
  tab: ServiceFormTabTypes.StationTable,
});

export function createServiceColumnList(type: ServiceType): ColumnsType<IServiceBase> {
  return [
    {
      title: 'Иконка',
      dataIndex: 'icon',
      render: (icon) => icon && (
        <img src={icon} width={24} height={24} alt="" />
      ),
    },
    {
      title: 'Название',
      dataIndex: ['info', 'title'],
      sorter: (a, b) => a.info.title < b.info.title ? -1 : 1,
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      render: (title, item) => (
        <Link className="w-full" to={serviceEditUrl(type, item.slug, item.status)}>{title || 'Без названия'}</Link>
      ),
    },
  ];
}

function stationRenderUrl(field: string, item: IStationServiceTableItem) {
  return <Link to={item.url}>{field}</Link>;
}

export function serviceStationTableColumnList(isList: boolean): ColumnsType<IStationServiceTableItem> {
  const result: ColumnsType<IStationServiceTableItem> = [
    {
      title: 'Вокзал',
      dataIndex: 'station',
      render: (station, item) => isList
        ? stationRenderUrl(station, item)
        : station,
    },
    {
      dataIndex: 'previewUrl',
      render: (url) => {
        return (
          <Link to={url} target="_blank">
            <ExportOutlined />
          </Link>
        );
      },
    },
  ];
  if (!isList) {
    result.unshift({
      title: 'Название',
      dataIndex: 'title',
      render: stationRenderUrl,
    });
  }
  return result;
}

export const serviceCRUDMap: Record<ServiceType, IStationLoader> = {
  [ServiceType.Simple]: {
    label: 'Обычные',
    labelCreateTitle: 'Создать услугу',
    labelCreateSuccess: 'Услуга создана',
    labelEditTitle: 'Редактирование услуги',
    labelEditSuccess: 'Услуга обновлена',
    useListLoad: useSimpleListLoadQuery,
    useItemLoad: useSimpleLoadQuery,
    useItemCreate: useSimpleCreateMutation,
    useItemRemove: useSimpleRemoveMutation,
    useItemUpdate: useSimpleUpdateMutation,
    useItemInfoUpdate: useSimpleInfoUpdateMutation,
    useItemStartModeration: useSimpleStartModerationMutation,
    useItemApprove: useSimpleApproveMutation,
    useItemDecline: useSimpleDeclineMutation,
    useItemPrevLoad: useLazySimpleLoadQuery,
    useItemRemoveDraft: useSimpleRemoveDraftMutation,
  },
  [ServiceType.Premium]: {
    label: 'Премиум',
    labelCreateTitle: 'Создать премиум услугу',
    labelCreateSuccess: 'Премиум услуга создана',
    labelEditTitle: 'Редактирование премиум услуги',
    labelEditSuccess: 'Премиум услуга обновлена',
    useListLoad: usePremiumListLoadQuery,
    useItemLoad: usePremiumLoadQuery,
    useItemCreate: usePremiumCreateMutation,
    useItemRemove: usePremiumRemoveMutation,
    useItemUpdate: usePremiumUpdateMutation,
    useItemInfoUpdate: usePremiumInfoUpdateMutation,
    useItemStartModeration: usePremiumStartModerationMutation,
    useItemApprove: usePremiumApproveMutation,
    useItemDecline: usePremiumDeclineMutation,
    useItemPrevLoad: useLazyPremiumLoadQuery,
    useItemRemoveDraft: usePremiumRemoveDraftMutation,
  },
  [ServiceType.Flagship]: {
    label: 'Флагманские',
    labelCreateTitle: 'Создать флагманскую услугу',
    labelCreateSuccess: 'Флагманская услуга создана',
    labelEditTitle: 'Редактирование флагманской услуги',
    labelEditSuccess: 'Флагманская услуга обновлена',
    useListLoad: useFlagshipListLoadQuery,
    useItemLoad: useFlagshipLoadQuery,
    useItemCreate: useFlagshipCreateMutation,
    useItemRemove: useFlagshipRemoveMutation,
    useItemUpdate: useFlagshipUpdateMutation,
    useItemInfoUpdate: useFlagshipInfoUpdateMutation,
    useItemStartModeration: useFlagshipStartModerationMutation,
    useItemApprove: useFlagshipApproveMutation,
    useItemDecline: useFlagshipDeclineMutation,
    useItemPrevLoad: useLazyFlagshipLoadQuery,
    useItemRemoveDraft: useFlagshipRemoveDraftMutation,
  },
};

// roles

export const SERVICE_ROLE_PREFIX = 'roles_services';

export function getServiceRoleAdmin(type: ServiceType) {
  return `${SERVICE_ROLE_PREFIX}_${type}_${ROLE_ADMIN_SUFFIX}`;
}

const serviceTypeRoleMap: Record<ServiceType, string> = {
  [ServiceType.Simple]: getServiceRoleAdmin(ServiceType.Simple),
  [ServiceType.Premium]: getServiceRoleAdmin(ServiceType.Premium),
  [ServiceType.Flagship]: getServiceRoleAdmin(ServiceType.Flagship),
};


export function canViewServices(roles: KeycloakRoles['roles']): boolean {
  return roles.some((item) => item.startsWith(SERVICE_ROLE_PREFIX));
}

export function canViewServiceTable(roles: KeycloakRoles['roles'], type: ServiceType): boolean {
  return roles.includes(serviceTypeRoleMap[type]);
}

export const simpleFormItems: IFormItemConfig[] = [
  createFormTextField({
    name: ['info', 'title'],
    label: 'Название',
  }),
  createFormTextField({
    name: 'gis_id',
    label: 'Gis ID',
    className: 'w-1/3',
  }),
  {
    type: FormItemTypes.CatalogSelector,
    component: CatalogCategorySelector,
    formItemProps: {
      name: 'service_catalog_id',
      label: 'Catalog Id',
    },
  },
  createFormLinkField('external_link', 'Внешняя ссылка'),
  createMediaIconFormField(SIMPLE_FILE_PREFIX),
  createRichTextFormField(SIMPLE_FILE_PREFIX),
];

export const serviceComplexFormItems: IFormItemConfig[] = [
  createFormTextField({
    name: ['info', 'title'],
    label: 'Название',
  }),
  createFormTextField({
    name: 'gis_id',
    label: 'Gis ID',
    className: 'w-1/3',
  }),
  {
    type: FormItemTypes.CatalogSelector,
    component: CatalogCategorySelector,
    formItemProps: {
      name: 'service_catalog_id',
      label: 'Catalog Id',
    },
  },
  createFormCheckboxField('is_station_list', 'Показывать вокзалы списком'),
  createFormLinkField('external_link', 'Внешняя ссылка'),
  createMediaIconFormField(SERVICE_FILE_PREFIX),
  createMediaAttachFormField(SERVICE_FILE_PREFIX),
  createRichTextFormField(SERVICE_FILE_PREFIX),
];
