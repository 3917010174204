import { IFormSelectOption } from 'features/Form';
import { ContactBlockTypes } from './Contact.interface';

export const contactBlockOptions: IFormSelectOption[] = [
  {
    label: 'Текст',
    value: ContactBlockTypes.Text,
  },
  {
    label: 'Адрес',
    value: ContactBlockTypes.Address,
  },
  {
    label: 'E-mail',
    value: ContactBlockTypes.Email,
  },
  {
    label: 'Телефон',
    value: ContactBlockTypes.Phone,
  },
  {
    label: 'Ссылка',
    value: ContactBlockTypes.Link,
  },
];
