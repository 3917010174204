import { Form, Input, Space } from 'antd';
import { FormCommonError, FormForm, formRuleEmail, formRuleRequired } from 'features/Form';
import { useCallback } from 'react';
import { IUser, IUserForm } from '../../User.interface';

export function UserBasicForm({ model, onFinish, onRemove }: {
  model?: IUser;
  onFinish: (values: IUserForm) => any;
  onRemove?: Function;
}) {
  const [form] = Form.useForm<IUserForm>();

  const handleFinish = useCallback(async (values: IUserForm) => {
    try {
      await onFinish(values);
    } catch (e) {
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [onFinish]);

  console.log('--m', model);

  return (
    <FormForm
      initialValues={model}
      form={form}
      onFinish={handleFinish}
      onRemove={model && onRemove}
    >
      <Form.Item
        label="Логин"
        name="username"
        className="w-1/3"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите логин')]}
      >
        <Input disabled={!!model} />
      </Form.Item>
      <Form.Item
        label={model ? 'Новый пароль' : 'Пароль'}
        name="password"
        className="w-1/3"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите пароль')]}
      >
        <Input.Password disabled={!!model} />
      </Form.Item>
      <Space>
        <Form.Item
          label="Фамилия"
          name="last_name"
          validateTrigger="onBlur"
          rules={[formRuleRequired('Введите фамилию')]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="first_name"
          validateTrigger="onBlur"
          rules={[formRuleRequired('Введите имя')]}
        >
          <Input />
        </Form.Item>
      </Space>
      <Form.Item
        label="E-mail"
        name="email"
        className="w-1/3"
        validateTrigger="onBlur"
        rules={[formRuleEmail('Введите e-mail')]}
      >
        <Input />
      </Form.Item>
    </FormForm>
  );
}
